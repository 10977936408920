import React from 'react';
import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange, JulianDate,Sandcastle} from '../../node_modules/cesium';
import 'cesium/Source/Widgets/widgets.css';

class CesiumContainer extends React.Component {
  componentDidMount() {
    const viewer = new Viewer('cesiumContainer', {
      imageryProvider: 
      new UrlTemplateImageryProvider({
        url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
        // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
  //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
        credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
      }),
      baseLayerPicker: false,
      timeline : false,
      animation : false,
      fullscreenButton: false,
      homeButton: false,
      vrButton: false,
      geocoder:false,
      sceneModePicker:false,
      navigationHelpButton:false,
    });

    var options = {
      camera: viewer.scene.camera,
      canvas: viewer.scene.canvas,
    };
    console.log(this.props.dir+this.props.file);
    console.log(this.props.kmlurl);

    var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(this.props.dir+this.props.file,options));
    // var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(this.props.dir+this.props.file,options));

    viewer.zoomTo(dataSourcePromise, new HeadingPitchRange(0, -1, 50000));
    viewer._cesiumWidget._creditContainer.style.display = "none";

    const year = this.props.year;
    var currentTime = new JulianDate.fromIso8601(year+"-07-11T16:00:00Z");

    viewer.clock.currentTime = currentTime;




  }
 


  render() {
    return <div><div id="cesiumContainer" /></div>;
  }
}

export default CesiumContainer

